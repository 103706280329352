import React from "react";
import "./all.css";
import { Header } from './Header';
import { useEffect, useState } from "react";
import { shuffle } from "lodash";
import emailjs from "emailjs-com";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const SERVICE_ID = "service_wdjh4a7";
const TEMPLATE_ID = "template_vrjeuov";
const USER_ID = "Z6Nwje68HcpD1-6Ii";


const Feedback = () => {
  const [tagsdata, setTagsdata] = useState([]);
  const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        feedback: ""
    });
   const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
   const isPhoneNumberValid = (phoneNumber) => {
    // Regular expression for basic phone number validation
    const phonePattern = /^[0-9]{10}$/; // Modify the pattern according to your phone number format
    return phonePattern.test(phoneNumber);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // If the input is for the phone number, only allow numeric characters
    if (name === "phone" && !/^\d*$/.test(value)) {
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);

  const sendemailform = (e) => {
    e.preventDefault();
    if (
      formData.name.trim().length < 4 ||
      formData.phone.trim().length !== 10 ||
      !isPhoneNumberValid(formData.phone) ||
      !isEmailValid(formData.email)
    ) {
      Swal.fire({
        icon: "error",
        title: "Please fill in all fields with valid data",
      });
      return;
    }
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };
 
  return (
  <div className="App">
  <Header />
  <div class="row">
     <div class="col-md-2"></div>
     <div class="col-md-8">
         <main>
            <form action method="POST" onSubmit={sendemailform}>
               <div class="submit-link-row">
                    <label for="id_name">Name:</label>
                    <br></br>
                    <input type="text" name="name" required id="id_name"/>
               </div>
               <div class="submit-link-row">
                    <label for="id_email">Email:</label>
                    <br></br>
                    <input type="email" name="email" required id="id_email"/>
               </div>
               <div class="submit-link-row">
                    <label for="id_phone_num">Phone:</label>
                    <br></br>
                    <input type="text" name="phone_num" required id="id_phone_num" />
               </div>
               <div class="submit-link-row">
                    <label for="id_feedback">Feedback:</label>
                    <br></br>
                    <input type="text" name="feedback" required id="id_feedback" />
               </div> 
               <button type="submit" name="submit" class="waves-effect waves-light btn submit-button pink mt-30">Send Feedback</button>
            </form>
         </main>
      </div>
   </div>
    <div className="container-footer">
        <footer>
          {/*<div>
            <p>copyright@9faqs.com</p>
          </div>*/}
          <span className="python-tags"> Tags:
          </span>
          {tagsdata[1]?.tags?.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            >
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
         <div></div>
          <br />
        <div className="footer-about">
        <a className="footer-about" href='/about'>About </a>  &nbsp;|
        <a className="footer-about" href='https://9faqs.com/feedback'>&nbsp;  Feedback</a> | copyright@9faqs.com
        </div>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/9FAQs" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/9faqs_official/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/9faqs" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div> 
        </footer>
      </div>
   </div>
  );
};

export default Feedback;
