import { useLocation } from 'react-router-dom';
import "./App.css";
import { Helmet } from 'react-helmet';
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import CanonicalTag from './components/CanonicalTag';
import axiosInstance from "./axioApi";
import { useEffect, useState} from "react";
import useFetch from "./useFetch";
// import Home from "./components/home";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { shuffle } from "lodash";
import { Text } from "react-native";
import ProfileDropdown from './ProfileDropdown';
import QuestionDetail from './components/QuestionDetail';

//import axiosInstance from "./axioApi";

const Result = (props) => {
  if (props.answerCheck === true) {
    return <p>Correct Answer</p>;
  } else if (props.answerCheck === false) {
    return <p>Wrong Answer</p>;
  } else {
  }
};

function App() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [question, setQuesion] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(false);
  const [answerCheck, setAnswerCheck] = useState("");
  const [selectedAnswerObject, setSelectedAnswerObject] = useState({});
  const [tagsdata, setTagsdata] = useState([]);

  // const [quizs] = useFetch("https://js.pythonfaqs.com/DFGHYTRE");
  
  const [quizs, setQuizs] = useState([]);


  useEffect(() => {
    fetch("react_data_prod.json")
      .then((res) => res.json())
      .then((data) => setQuizs(shuffle(data)));
  }, []); 

 useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []); 


  const [answerSelected, setAnswerSelected] = useState("");

  const [answer, setAnswer] = useState(true);
  const [color, setColor] = useState("");
  const wrongBtn = document.querySelector("button.bg-danger");
  wrongBtn?.classList.remove("bg-danger");
  const rightBtn = document.querySelector("button.bg-success");
  rightBtn?.classList.remove("bg-success");


  {/* async function handleLogout() {
    try {
      const response = await axiosInstance.post("/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      console.log(localStorage);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;
      console.log(axiosInstance.defaults);
      //window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  }
 
*/}

 async function handleLogout() {
    try {
      const response = await axiosInstance.post("blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      console.log(localStorage);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("email");
      axiosInstance.defaults.headers["Authorization"] = null;
      console.log(axiosInstance.defaults);
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  } 


  useEffect(() => {
    if (quizs.length > questionIndex) {
      setQuesion(quizs[questionIndex]);
    }
  }, [quizs, questionIndex]);

  const nextQuestion = () => {
    setAnswerCheck("");
    setSelectedAnswer("");
    setAnswerSelected("");
    setColor("");
    setIsLoading("");
    setSelectedAnswerObject({});
    const newIndex = questionIndex + 1;
    setQuestionIndex(newIndex);

    if (quizs[newIndex]) {
      const nextQuestion = quizs[newIndex];
      navigate(`/python/${nextQuestion.primary_tag}/${nextQuestion.random_id}`);
    }
  };



 const nextQuestion2 = () => {
    setAnswerCheck("");
    setSelectedAnswer("");
    setAnswerSelected("");
    setColor("");
    setIsLoading("");
    setSelectedAnswerObject({});
    setQuestionIndex(0);
  };
 

  const handleAnswerButtonClick = (answer) => {
    setSelectedAnswerObject(answer);
    if (answer.is_correct) {
      // e.target.classList.add('button.bg-success');
      setIsLoading(true);
      setAnswerCheck(true);
      setAnswerSelected(true);
    } else {
      setIsLoading(true);
      // e.target.classList.add('button.bg-danger');
      setAnswerCheck(false);
      setAnswerSelected(true);
      setColor(false);
    }
  };

  const getClass = (option) => {
    if (answerSelected === "") {
      return "";
    }
    if (option === answerSelected) {
      return "btn bg-success";
    }
    if (option === color) {
      return "btn bg-danger";
    }
  };
  
  const [showSharePopup, setShowSharePopup] = useState(false);

  const questionId = question.id;
  const randomId= question.random_id;
  const primaryTag = question.primary_tag;
  const questionUrl = `https://9faqs.com/python/${primaryTag}/${randomId}`;
  const handleShareClick = () => {
    setShowSharePopup(true);
  };

  const handleOptionSelect = (option) => {
    let shareText = `Question: ${question.question_title}\n\n`;
    shareText += `Code: ${question.code}\n\n`;
    shareText += "Options:\n";
    question.answers.forEach((answer, index) => {
        shareText += `${index + 1}. ${answer.answer}\n`;
    });
    shareText += "Refer 9faqs.com to solve more\n";
    shareText += `${questionUrl}\n`
    shareText += "#pythonprogramming\n#Python\n#9faqs\n#LearningJourney\n#quiz_time\n#Training\n"
    let shareUrl;
    switch (option) {
      case 'LinkedIn':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(questionUrl)}&summary=${encodeURIComponent(shareText)}`;
        break;
      case 'X':
        shareUrl = `https://x.com/share?text=${encodeURIComponent(shareText)}`;
        break;
      case 'Facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(questionUrl)}&quote=${encodeURIComponent(shareText)}`;
        break;
      // Add more cases for additional sharing options
      default:
        break;
    }

    // Open the share URL
    window.open(shareUrl);

    // Close the share popup
    setShowSharePopup(false);
  }; 
  const question_name = question.question_title || ''
  const questionTitle = question.question_title || ''
  const options = question.answers?.map((answer, index) => `${index + 1}. ${answer.answer}`).join('\n') || '';
  const code = question.code || '';
  const meta_desc = `Question: ${questionTitle}\n\nCode: ${code}\n\nOptions:\n${options}`;

  const [ogTags, setOgTags] = useState({
    ogTitle: question_name,
    ogDescription: meta_desc,
    ogUrl: questionUrl,
  });
  
  useEffect(() => {
    const newOgTags = {
      ogTitle: question_name,
      ogDescription: meta_desc,
      ogUrl: questionUrl,
    };
    setOgTags(newOgTags);
    updateMetaTags(newOgTags);
  
  }, [question]);

  const updateMetaTags = (ogTags) => {
  const ogTitleMeta = document.querySelector('meta[property="og:title"]');
  const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
  const ogUrlMeta = document.querySelector('meta[property="og:url"]');

  if (ogTitleMeta) {
    ogTitleMeta.setAttribute('content', ogTags.ogTitle);
  }

  if (ogDescriptionMeta) {
    ogDescriptionMeta.setAttribute('content', ogTags.ogDescription);
  }

  if (ogUrlMeta) {
    ogUrlMeta.setAttribute('content', ogTags.ogUrl);
  }
  };

  const toComponentB = () => {
    navigate("/report", { state: question });
  }; 
  const location = useLocation();
  return (
    <div className="App">
      <CanonicalTag url={"https://9faqs.com/python"}/>
      <Helmet>
         <meta property="og:title" content={`9FAQs | Python MCQs, ${question.primary_tag}, ${question.question_title}`} />
        <meta property="og:description" content={`9FAQs | Python MCQs, ${question.primary_tag}, ${question.question_title}, Tags, Python Training, Quiz`} />
         <meta property="og:url" content={`https://9faqs.com/python/${question.primary_tag}/${question.random_id}`} />
      </Helmet>
      <div className="container-2">
        <header>
         <div className="col-md-3">
         <h1><a className="logo" href="https://9faqs.com/python">9FAQs<sup>α</sup> <span className="sub-logo">| PythonFAQs</span><br />
          <p className="Versionss"> (β version to be launched shortly)</p>
          </a></h1>
          
          </div>
          <div className="img-logo" style={{ width: "60%",}}>
            <img src ={"independence.png"} />
          </div>

        <div className="tagline" >
            <p>Best & Easiest Way to Self-Master Python</p>
            <div className="menu">
                <Link to ="/python" className={`menu-item ${location.pathname === '/python' ? 'active' : ''}`}>
                 <strong>FAQS</strong>
                </Link>
                <Link to ="/training" className={`menu-item ${location.pathname === '/training' ? 'active' : ''}`}>
                 <strong>PyTraining</strong>
                </Link>
            </div>
          </div>
        <div className="tagline">
            <Link to="/python">
        <FontAwesomeIcon icon={faHome} />
        </Link>
       </div>
          
        {/*<div className= "tagline">
          {!localStorage.refresh_token ? (
            <Link to="/login">
              <strong>Login</strong>
            </Link>
          ) : (
               <ProfileDropdown handleLogout={handleLogout} />
          )}
          </div> */}

         {/* {!localStorage.refresh_token ? (
            <Link to="/login">
              <strong>♥  Login</strong>
            </Link>
          ) : (
            <button style={{ backgroundColor: "white", border:"white", }} onClick={handleLogout}><strong>♥  Logout</strong></button>
          )} */}
 
        </header>
      </div>
      <div className="Main-class">
        <div
          className="container"
          style={{
            width: "60%",
            fontFamily: "Roboto, sans-serif",
            textAlign: "center",
          }}
        >
        
          <Text>
            <h2>{question.question_title} </h2>
          </Text>       

         <code><Text>
            {question.code && (
              <h5>
                <div className="container-code">
                  <div className="container-code">{question.code}</div>
                </div>
              </h5>
            )}
          </Text></code>
 

          <br />

          <div className="answer-section">
            {question.answers?.map((answer) => {
              if ( selectedAnswerObject.id && selectedAnswerObject.id === answer.id && selectedAnswerObject.is_correct
              ) {
                return (
                  <ul key={answer.id}>
                    <li>
                      <button
                        disabled={isLoading}
                        className={getClass(answer.is_correct)}
                        type="radio"
                        name="option"
                        value={answer.is_correct}
                        onClick={() => handleAnswerButtonClick(answer)}
                      >
                        {answer.answer}
                      </button>
                    </li>
                  </ul>
                );
              } else if ( selectedAnswerObject.id && selectedAnswerObject.id === answer.id && !selectedAnswerObject.is_correct
              ) {
                return (
                  <ul key={answer.id}>
                    <li>
                      <button
                        disabled={isLoading}
                        className="btn bg-danger"
                        type="radio"
                        name="option"
                        value={answer.is_correct}
                        onClick={() => handleAnswerButtonClick(answer)}
                      >
                        {answer.answer}
                      </button>
                    </li>
                  </ul>
                );
              } else
                return (
                  <ul key={answer.id}>
                    <li>
                      <button
                        disabled={isLoading}
                        type="radio" name="option"
                        className={
                          // selectedAnswerObject.id === undefined ? "" : ""
                          selectedAnswerObject.id !== undefined && selectedAnswerObject.is_correct !== true ? answer.is_correct ? "btn btn-success": "": ""
                        }
                        value={answer.is_correct}
                        onClick={() => handleAnswerButtonClick(answer)}
                      >
                        {answer.answer}
                      </button>
                    </li>
                    {/* className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${correctAnswer === item && 'bg-success'}`} */}
                  </ul>
                );
            })}

            {/*  */}

            {/* <Button variant="secondary" size="sm" value="submit"  onClick={checkAnswer}>Submit</Button> */}
            {/* <button variant="secondary" style={{backgroundColor: "white"}} value="submit"  onClick={checkAnswer}>Submit</button><br /> */}
          </div>

          {isLoading && (
            <div>
             {/* <p>
              <strong>Explanation:</strong>
                <br />
                {question.explanation}
              </p> */}
            </div>
          )}
          <h4 className="author-tags-class">Author: {question.user_name} {question.tag_name && <span> | Tags: {question.tag_name}</span>} | {tagsdata?.map((tagname, i) => ( <span>{tagname.count}</span> ))}+ Python FAQs | <a href="/report" onClick={() => { toComponentB();}}
          >&nbsp; Report This Q.</a> | <a onClick={handleShareClick} style={{ cursor: 'pointer' }}>Share This Q</a></h4>
            
          {showSharePopup && (
        <div className="share-popup" style={{ cursor: 'pointer' }}>
          <div className="share-popup-content">
            {/* Share options */}
            <div>
              {/* Share icon for X */}
              <span onClick={() => handleOptionSelect('X')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  {/* Icon for X (Twitter) */}
                  <path d="M23.963 4.56c-.885.392-1.838.658-2.842.778 1.019-.611 1.798-1.573 2.167-2.719-.951.562-2.006.972-3.133 1.197-.899-.962-2.183-1.56-3.607-1.56-2.734 0-4.948 2.214-4.948 4.947 0 .388.044.766.127 1.128C7.77 8.954 4.114 7.21 1.67 4.077 1.248 4.825.98 5.68.98 6.587c0 1.717.875 3.232 2.207 4.12-.812-.026-1.573-.25-2.234-.625v.062c0 2.395 1.7 4.388 3.954 4.84-.415.114-.85.174-1.298.174-.317 0-.625-.03-.928-.086.625 1.92 2.437 3.315 4.586 3.35-1.679 1.312-3.786 2.094-6.078 2.094-.394 0-.783-.022-1.17-.067C2.563 21.307 5.516 22 8.667 22c8.199 0 12.675-6.797 12.675-12.676 0-.193-.005-.384-.015-.573.869-.625 1.622-1.407 2.218-2.297z"></path>
                </svg>
              </span>&nbsp; | &nbsp; 

              {/* Share icon for Facebook */}
              <span onClick={() => handleOptionSelect('Facebook')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12c0 6.23 4.551 11.362 10.502 11.868v-8.372H7.399V12h3.103V9.275c0-3.073 1.821-4.775 4.642-4.775 1.346 0 2.502.101 2.835.146V7.9h-1.944c-1.525 0-1.821.724-1.821 1.785V12h3.679l-.48 3.628h-3.199v8.77C19.447 23.503 24 18.371 24 12 24 5.373 18.627 0 12 0Z" fill="#3b5998"></path>
                </svg>
              </span>&nbsp; | &nbsp;  

              {/* Share icon for LinkedIn */}
              <span onClick={() => handleOptionSelect('LinkedIn')}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M21,0H3C1.3,0,0,1.3,0,3v18c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V3C24,1.3,22.7,0,21,0z M8.7,19.8H5.3V9.6h3.5V19.8z M7,8.7l0,0c-1.1,0-1.8-0.7-1.8-1.6C5.2,5.9,5.9,5.3,6.8,5.3c0.7,0,1.2,0.4,1.4,0.9C8.3,6.2,8.2,7.4,7,8.7z M20,19.8h-3.4v-5.3 c0-1.3-0.5-2.2-1.8-2.2c-0.9,0-1.5,0.6-1.8,1.2c-0.1,0.2-0.2,0.5-0.2,0.7v5.6H9.1V9.6h3.3v1.3c0.5-0.7,1.5-1.7,3.7-1.7 c2.7,0,4.7,1.7,4.7,5.4V19.8z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      )}
        
           {isLoading && (questionIndex + 1 !== quizs.length ? (
            <button
              className="Next-question"
              onClick={nextQuestion}
            >
              Next Question
            </button>
          ) : (
           <button
              className="Next-question"
              onClick={nextQuestion2}
            >
              Next Question
            </button> 
          ))}
        </div>
      </div>

     {/*  <footer>
        <hr />
        <a className="footer" target="_blank" rel="noopener">
          {" "}
          pythonfaqs.com
        </a>
      </footer> */}

     <div className="container-footer">
        <footer>
          {/*<div>
            <p>copyright@9faqs.com</p>
          </div>*/}
          <span className="python-tags">
          </span>
          {tagsdata[1]?.tags.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            >
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
          <div></div> 
          <br />
          <div className="footer-about">
          <a className="footer-about" href='/about'>  About &nbsp;| </a> 
          <a className="footer-about" href='https://9faqs.com/feedback'>&nbsp;  Feedback &nbsp;| </a> copyright@pythonfaqs.com
          </div>
          <div className="social-media-icons">
          <a href="https://www.facebook.com/9FAQs" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/9faqs_official/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/9faqs" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        </footer>
      </div>
 
    </div>
  );
}

export default App;
