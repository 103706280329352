import { useEffect, useState } from "react";
import React from "react";
import { shuffle } from "lodash";
import "../App.css";
import "./all.css";

function Footer() {
  const [tagsdata, setTagsdata] = useState([]);

  useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(shuffle(data)));
  }, []);

  //   console.log(tagsdata);

  return (
    <div className="container-footer">
      <footer>
        {/* <div className="footer-about">
          <p>copyright@pythonfaqs.com</p>
        </div>*/}
        <span className="python-tags"></span>
        {tagsdata[1]?.tags?.map((tag_name, i) => (
          <span className="python-tags" key={tag_name.id}>
            {tag_name.name}
            {i < tagsdata[1].tags.length - 1 ? " | " : null}
          </span>
        ))}
        <div></div>
        <br />
        <div className="footer-about">
          <a className="footer-about" href="https://9faqs.com/about">
            About{" "}
          </a>{" "}
          |
          <a
            className="footer-about"
            href="https://9faqs.com/feedback"
          >
            {" "}
            Feedback
          </a>{" "}copyright@pythonfaqs.com
        </div>
      </footer>
    </div>
  );
}

export default Footer;
