import React from "react";
import "./all.css";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Header }  from "./Header"
import { shuffle } from "lodash";
import { useEffect, useState } from "react";


const About = () => {
  const [tagsdata, setTagsdata] = useState([]);

  useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []); 

  return (
        <div className="App">
        <Header />
        <div className='Aboutpage'>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <h4>The best way to learn Python is with hands-on practice. <br /><br />Python is intuitive, so focusing on coding challenges will improve your skills.</h4><br />
                <p><h5>
                    Easy to learn and use:
                </h5> python syntax is straightforward and generally similar to the English language</p><br />
                <p><h5>Expressive: </h5>Python is able to perform complex tasks using just a few lines of code.</p><br />
                <p><h5>Interpreted Language: </h5>Python is an interpreted language, meaning that a Python program is executed line by line</p>
                <div className='privacy'>
                    <strong>Privacy Policies</strong>
                    <p> Any email address and personal name provided to us is considered personal data as defined and protected</p>
                    <p>By using the 9faqs.com website you can understand that your data in relation to your use of our Service is processed.</p>
                    <p>When registering on our site, as appropriate, you may be asked to enter your email address or other details to help you with your experience.To improve our website in order to better serve you.</p>
                    <p>We do not include or offer third party products or services on our website.</p>
                    <div className='contact'>
                        <p><strong>Contacting Us</strong></p>
                        <p>If there are any questions regarding privacy policy you may contact us- raja@datazeneral.com</p>
                    </div>
                </div>
            </div>
            <div class="row">
             <div class="col-md-2"></div>             
             <div class="col-md-8">
                <h4 class="section-title text-uppercase">TEAM</h4>                
            </div>
            </div>
            <div class='row' style={{ paddingTop: '0%', paddingBottom: '0%' }}>
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class='row'>
                        <div class="col-md-6">
                            <img src={"Kaivalya.png"} alt="Kaivalya" className="team-image" style={{ width: '100px', height: '100px', border: '2px solid #000', marginBottom: '10px' }} />
                            <div>
                                <b><p className="team-mems"> Kaivalya</p></b>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={"Devkanth.png"} alt="Devkanth" className="team-image" style={{ width: '100px', height: '100px', border: '2px solid #000', marginBottom: '10px' }} />
                            <div>
                                <b><p className="team-mems"> Devakanth </p> </b>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-md-6">
                            <img src={"Venu.png"} alt="Venu" className="team-image" style={{ width: '100px', height: '100px', border: '2px solid #000', marginBottom: '10px' }} />
                            <div>
                                <b><p className="team-mems"> Venu </p> </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="container-footer">
            <footer>
                <span className="python-tags"> Tags:
                </span>
                {tagsdata[1]?.tags?.map((tag_name, i) => (
                    <span className="python-tags"
                        key={tag_name.id}
                    >
                        {tag_name.name}
                        {i < tagsdata[1].tags.length - 1 ? " | " : null}
                    </span>
                ))}
                <div></div>
                <br />
        <div className="footer-about">
        <a className="footer-about" href='/about'>About &nbsp;</a>  |
        <a className="footer-about" href='https://9faqs.com/feedback'>&nbsp;  Feedback</a> | copyright@9faqs.com
        </div>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/9FAQs" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/9faqs_official/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://x.com/9faqs" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        </footer>
      </div> 
   </div>
  );
};

export default About;
