import React, { Component } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axioApi";


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {email: "", password: ""};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }


async handleSubmit(event) {
    // const navigate = useNavigate();
    event.preventDefault();
    try {
        await axiosInstance.post('/token/obtain/', {
            email: this.state.email,
            password: this.state.password
        }).then((response) => {
            console.log("logindata", response);
            localStorage.setItem('access_token', response.data?.access);
            localStorage.setItem('refresh_token', response.data?.refresh);
            localStorage.setItem("email", this.state.email);
            window.location.assign('/');
        }).catch ((error) => {
            console.log(error);
        })

    } catch (error) {
        throw error;
    }
}

    render() {
              
        return (
            <div className="Auth-form-container">
            <Helmet>
                    <meta name="robots" content="no index, no folow" />

                </Helmet>
            <form className="Auth-form" onSubmit={this.handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address

            <input className="form-control mt-1" name="email" type="text" value={this.state.email} onChange={this.handleChange}
            /></label>
          </div>
          <div className="form-group mt-3">
            <label>Password
            <input className="form-control mt-1" name="password" type="password" value={this.state.password} onChange={this.handleChange}/></label>
          </div><br />
          <div>
            <button style= {{ width: "10% !important" }} type="submit" className="btn btn-secondary">
              Submit
            </button>
          </div>
          
        </div>
      </form>
            </div>
        
        )
    }
}

export default Login;
