import React from "react";
import { useState, useEffect } from "react";
import API from "./API.js";
//import "./all.css";
import Swal from "sweetalert2";
import { Header } from "./Header";
import Footer from './Footer';
import '../App.css';


const AddQuestion = () => {
  const [inputList, setInputList] = useState([
    { answer: "", is_correct: false },
  ]);
  const [tagsList, setTagsList] = useState([{ tag_name: "" }]);
  
  const [tagsdata, setTagsdata] = useState([]);

    useEffect(() => {
    fetch("react_9pytags.json")
      .then((res) => res.json())
      .then((data) => setTagsdata(data));
  }, []);
 
  const data_ = {
    question_title: "",
    code: "",
    author: localStorage.getItem("email"),
    explanation: "",
    mlevel: 10,
    is_active: false,
    answers_g: [{ answer: "", is_correct: false }],
    tags_g: [{ tag_name: "" }],
  };

  const [totalq, setTotalq] = useState(data_);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(totalq);
    API.post("/", totalq).then(
      (result) => {
        Swal.fire({
          icon: "success",
          title: "Question added successfully",
        });
      },
      (error) => {
        console.log(error.request.responseText);
        Swal.fire({
          icon: "error",
          title: "Ooops, Enter the fields correctly",
          text: error.request.responseText,
        });
      }
    );
  };

  // const handlesubmit = (e) => {
  //   e.preventDefault();

  //   // setTotalq({ answers: inputList });
  //   console.log(totalq);
  //   console.log(totalq);
  // };

  //answer-section
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setTotalq({ ...totalq, answers_g: inputList });
  };

  const handleInputChangecheck = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    // console.log(checked);
    // console.log(list);
    // setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { answer: "", is_correct: false }]);
  };

  //tagssection

  const handletagsInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...tagsList];
    list[index][name] = value;
    setTagsList(list);
    setTotalq({ ...totalq, tags_g: tagsList });
    // console.log(list);
    // console.log(totalq);
  };

  // console.log(inputList);
  const handletagsRemoveClick = (index) => {
    const list = [...tagsList];
    list.splice(index, 1);
    setTagsList(list);
  };

  // handle click event of the Add button
  const handletagsAddClick = () => {
    setTagsList([...tagsList, { tag_name: "" }]);
  };

  return (
   <div>
   <Header />
    <div>
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "9%",
          width: "90%",
        }}
      >
        Add Question
      </h2>

      <br />
      <div style={{ textAlign: "left", padding: "5% 10%" }}>
        {localStorage.refresh_token ? (
          <form className="form-class"  onSubmit={handlesubmit}>
            <label>Question: </label> <br />
            <textarea
              type="text"
              // style={{ width: "300%" }}
              value={totalq.question_title}
              autoFocus="autoFocus"
              required
              rows="5"
              cols="70"
              onChange={(e) =>
                setTotalq({
                  ...totalq,
                  question_title: e.target.value,
                  // author: localStorage.email,
                })
              }
            ></textarea>
            <br />
            <label>codedata </label> <br />
            <textarea
              type="text"
              // style={{ width: "300%" }}
              rows="5"
              cols="70"
              value={totalq.code}
              autoFocus="autoFocus"
              onChange={(e) => setTotalq({ ...totalq, code: e.target.value })}
            ></textarea>
            <br />
            <label>author </label> <br />
            <input
              key={localStorage.email}
              type="text"
              // options={options}
              value={totalq.author}
              name="author"
              // style={{ width: "100%" }}
              // onChange={(e) => setTotalq({ ...totalq, author: e.target.value })}
            />
            <br />
            <label>is_active </label> <br />
           <input
              className="form-activity"
              type="checkbox"
              defaultChecked={false}
              name="is_active"
              onChange={(e) =>
                setTotalq({
                  ...totalq,
                  is_active: e.target.checked,
                })
              }
            /> 
            <br />
            <label>mlevel </label> <br />
            <input
              type="number"
              name="mlevel"
              required
              value={totalq.mlevel}
              autoFocus="autoFocus"
              onChange={(e) => setTotalq({ ...totalq, mlevel: e.target.value })}
            />
            <br />
            <label>Explanation </label> <br />
            <textarea
              type="text"
              rows="5"
              cols="70"
              required
              // style={{ width: "300%" }}
              value={totalq.explanation}
              autoFocus="autoFocus"
              onChange={(e) =>
                setTotalq({ ...totalq, explanation: e.target.value })
              }
            ></textarea>{" "}
            <br />
            <br />
            <h4>Answer section</h4>
            {inputList.map((x, i) => {
              return (
                <div className="tabular inline-related " key={i}>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="column-answer required">Answer</th>
                        <th className="column-is_correct">Is correct</th>
                        <th className="column-answer required"></th>
                        <th className="column-answer required"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className="form-row row2  empty-form"
                        id="answers-empty"
                      >
                        <td className="field-answer">
                          <textarea
                            style={{ width: "0% !important" }}
                            name="answer"
                            required
                            rows="5"
                            cols="70"
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </td>
                        <td className="field-is_correct">
                          <input
                            className="form-activity-correct"
                            type="checkbox"
                            defaultChecked={false}
                            name="is_correct"
                            //   onChange={(e) => setCheck(!check)}
                          />
                        </td>
                        <td>
                          {inputList.length !== 1 && (
                            <button
                              style={{
                                width: "70%",
                                height: "20%",
                                wordWrap: "break-word",
                                fontSize: "100%",
                              }}
                              value="Remove"
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </button>
                          )}
                          <br />
                          {i < 3 && inputList.length - 1 === i && (
                            <button
                              style={{
                                width: "70%",
                                height: "20%",
                                wordWrap: "break-word",
                                fontSize: "100%!important ",
                              }}
                              value="Add"
                              onClick={handleAddClick}
                            >
                              Add
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
            <br />
            <h4>Tags Section</h4>
            {tagsList.map((x, i) => {
              return (
                <div className="tabular inline-related " key={i}>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="column-answer required">tag</th>
                        <th className="column-answer required"></th>
                        <th className="column-answer required"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className="form-row row2  empty-form"
                        id="answers-empty"
                      >
                        <td className="field-answer">
                          <textarea
                            rows="3"
                            cols="50"
                            style={{ width: "0% !important" }}
                            name="tag_name"
                            value={x.tag_name}
                            onChange={(e) => handletagsInputChange(e, i)}
                          />
                        </td>

                        <td>
                          {tagsList.length !== 1 && (
                            <button
                              style={{ width: "130px" }}
                              className="mr10"
                              onClick={() => handletagsRemoveClick(i)}
                            >
                              Remove
                            </button>
                          )}
                          <br />
                          {i < 3 && tagsList.length - 1 === i && (
                            <button
                              style={{ width: "70px" }}
                              onClick={handletagsAddClick}
                            >
                              Add
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
            <br />
            <button
              style={{ width: "100px", height: "30px" }}
              type="submit"
              onClick={handlesubmit}
              value="Submit"
            >
              Submit
            </button>
          </form>
        ) : (
          <p>Login to the website to add question</p>
        )}
      </div>
      <div className="container-footer">
        <footer>
          <div style={{ textAlign: "center !important"}}>
            <p>copyright@pythonfaqs.com</p>
          </div>
         <br /> <span className="python-tags"> Tags:
          </span>
          {tagsdata[1]?.tags?.map((tag_name, i) => (
            <span className="python-tags"
              key={tag_name.id}
            >
              {tag_name.name}
              {i < tagsdata[1].tags.length - 1 ? " | " : null}
            </span>
          ))}
          <div></div>
          <br />
        <div className="footer-about" style={{ textAlign: "center !important"}}>
        <a className="footer-about" href='/about'>About </a>  |
        <a className="footer-about" href='https://9faqs.com/feedback'>  Feedback</a>
        </div>
        </footer>
      </div>
    </div>
   </div>
  );
};

export default AddQuestion;

