import React from "react";
import "../App.css";
import axiosInstance from "../axioApi";
import { useEffect, useState } from "react";
// import Home from "./components/home";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import "bootstrap/dist/css/bootstrap.min.css";
import { shuffle } from "lodash";
import { Text } from "react-native";
import { useLocation } from 'react-router-dom';

export const Header = () => {
  /* async function handleLogout() {
    try {
      const response = await axiosInstance.post("/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      console.log(localStorage);
      axiosInstance.defaults.headers["Authorization"] = null;
      console.log(axiosInstance.defaults);
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  } */
   async function handleLogout() {
    try {
      const response = await axiosInstance.post("blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      console.log(localStorage);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("email");
      axiosInstance.defaults.headers["Authorization"] = null;
      console.log(axiosInstance.defaults);
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  }
  const location = useLocation();
  return (
    <div className="container-2">
      <header>
        <div className="col-md-3">
          <h1>
            <a className="logo" href="https://9faqs.com/python">
              9FAQs<sup>α</sup> <span className="sub-logo">| PythonFAQs</span>
              <br />
              <p className="Versionss"> (β version to be launched shortly)</p>
            </a>
          </h1>
        </div>
        <div className="img-logo" style={{ width: "60%"}}>
                 <img src ={"/independence.png"} />
        </div>

        <div className="tagline">
          <p>Best & Easiest Way to Self-Master Python</p>
          <div className="menu">
              <Link to ="/python" className={`menu-item ${location.pathname === '/python' ? 'active' : ''}`}>
               <strong>FAQS</strong>
              </Link>
              <Link to ="/training" className={`menu-item ${location.pathname === '/training' ? 'active' : ''}`}>
               <strong>PyTraining</strong>
              </Link>
          </div>
        </div>
       <div className="tagline">
          <Link to="/python">
             <FontAwesomeIcon icon={faHome} />
        </Link>
       </div>        

       {/*<div className= "tagline">
          {!localStorage.refresh_token ? (
            <Link to="/login">
              <strong>Login</strong>
            </Link>
          ) : (
          <ProfileDropdown handleLogout={handleLogout} />
        )}
          </div>*/}

        {/* {!localStorage.refresh_token ? (
            <Link to="/login">
              <strong>♥  Login</strong>
            </Link>
          ) : (
            <button style={{ backgroundColor: "white", border:"white", }} onClick={handleLogout}><strong>♥  Logout</strong></button>
          )} */}
      </header>
    </div>
  );
};


export default Header;
